import '@/css/app.pcss';
import { app } from '@/js/main';

import.meta.glob(['@/images/**', '@/fonts/**/*.woff2']);

window.addEventListener('DOMContentLoaded', app);

/**
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept('@/js/main', newApp => {
    newApp?.app();
  });
}
